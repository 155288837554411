import {cloneDeep} from "lodash/lang";
import {
    getPropName,
    isArrayNullOrEmpty,
    filterObjectArrayByField,
    getDistinctIds,
    createListItem,
    removeDuplicatesFromListArray, isNullOrUndefined,
} from "../commonUtilities";
import {searchCriteria, SearchFilterElements} from "./searchCriteria";

export function PtsSearchCriteria(sortByColumn = "projectName", baseSortByColumn = "projectName") {
    return {
        ...searchCriteria("PtsSearchCriteria", sortByColumn, baseSortByColumn, 20),
        programName: "",
        allPrograms: [],
        discretionaryProjectStatusDescription: "",
        applyFiltersAndOrder: ApplyFiltersAndOrder,
        areRulesCurrent: AreRulesCurrent,
        clearOptionalSearchItems: ClearOptionalSearchItems,
        setProgramList: SetProgramList,
        setVisibilityRules: SetVisibilityRules,
    };
}

export const PtsFilterElements = {
    ...SearchFilterElements,
    ...{
        discretionaryProjectStatusDescription: "discretionaryProjectStatusDescription"
    }
};

export const PtsFilterVisibilityRules =
    new (function () {
        this.shared = [
            PtsFilterElements.dateWindowId,
            PtsFilterElements.RecordsPerPage,
            PtsFilterElements.clearButton
        ];
        this.AdminSummaryWrapper = [
            ...this.shared,
            PtsFilterElements.programName,
            PtsFilterElements.teamMember,
            PtsFilterElements.discretionaryProjectStatusDescription,
            PtsFilterElements.fiscalAgent
        ];
        this.FiscalAgentSummaryWrapper = [
            ...this.shared,
            PtsFilterElements.programName,
            PtsFilterElements.fiscalAgentHidden,
            PtsFilterElements.teamMemberHidden,
            PtsFilterElements.discretionaryProjectStatusDescription
        ];
        this.AdminWrapper = [
            PtsFilterElements.dateWindowId,
        ];
        this.Program = [
            PtsFilterElements.programName,
            PtsFilterElements.clearButton
        ];
        this.Import = [
            PtsFilterElements.dateWindowId,
            PtsFilterElements.clearButton
        ]
    })();

function SetProgramList(allPrograms) {
    const programNames = allPrograms.map(({programName}) => createListItem(programName, programName));
    this.allPrograms = removeDuplicatesFromListArray(programNames);
}

function SetVisibilityRules(whichRules) {
    const rulesName = getPropName(PtsFilterVisibilityRules, whichRules);
    this.baseSetVisibilityRules(rulesName, whichRules, this.BaseSortByColumn);
}

function AreRulesCurrent(whichRules) {
    return this.baseAreRulesCurrent(whichRules, PtsFilterVisibilityRules);
}

function ClearOptionalSearchItems(clearTeamMembersAsWell = true, clearOutAll = false) {
    this.baseClearOptionalSearchItems(clearTeamMembersAsWell, clearOutAll);

    if (this.isVisible(PtsFilterElements.programName) || clearOutAll)
        this.programName = "";

    if (this.isVisible(PtsFilterElements.discretionaryProjectStatusDescription) || clearOutAll)
        this.discretionaryProjectStatusDescription = "";
}

function ApplyFiltersAndOrder(summaryData) {
    if (isArrayNullOrEmpty(summaryData))
        return [];

    let summary = cloneDeep(summaryData);
    summary = this.baseApplyFilters(summary);

    if(isArrayNullOrEmpty(summary))
        return [];

    if (this.programName && this.isVisible(PtsFilterElements.programName))
        summary = summary.filter(f => isNullOrUndefined(f.programName) || f.programName.toLowerCase() === this.programName.toLowerCase());

    if (this.discretionaryProjectStatusDescription && this.isVisible(PtsFilterElements.discretionaryProjectStatusDescription))
        summary = summary.filter(f => f.discretionaryProjectStatusDescription.toLowerCase().endsWith(this.discretionaryProjectStatusDescription.toLowerCase()));

    if (this.teamMember && (this.isVisible(PtsFilterElements.teamMember) || this.isVisible(PtsFilterElements.teamMemberHidden))) {
        const associatedMember = this.teamMembers.filter(m => m.userId === this.teamMember || m.gmsUserId === this.teamMember);
        const associatedFiscalAgents = getDistinctIds(associatedMember, "userId");
        summary = filterObjectArrayByField(summary, associatedFiscalAgents.toString(), "discretionaryProjectUserIds");
    }

    return this.baseSort(summary);
}