import {
    createDescriptionObjectForList,
    createListItem, getPropName, isArray
} from "../components/common/commonUtilities";
import * as contexts from "./contexts";
import * as contextDisplay from "./contextDisplay";

const server = (process.env.NODE_ENV === 'production') ? "/" : "http://localhost:64410/";

export const supportEmail = "support+gsw@fcim.org";
export const bpieSupportEmail = "lsi-finadmin@fsu.edu";

export function supportDetails(currentContexts) {
    const context = !currentContexts ? "" : isArray(currentContexts) ? currentContexts[0] : currentContexts;
    const contextKey = getPropName(contexts, context);
    // eslint-disable-next-line import/namespace
    const contextTitle = contextKey && contextDisplay[contextKey] ? ` ${contextDisplay[contextKey]}` : "";

    const subject = `GSW${contextTitle}`;
    const supportTicket = getSupportTicket(subject);
    return `please call (800) 357-1072 or ${supportTicket}`;
}

export function getSupportTicket(subject, text = `create a support ticket`) {
    return `<a href=\"https://fcim.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&searchArea=no&captcha=yes&helpdesk_ticket[subject]=${subject}&disable[subject]=true\" target=\"_blank\" rel=\"noopener noreferrer\">${text}</a>`;
}

export const apiUrl = server + "api/";
export const apiMaxRetryCount = 10;

export const serverUrl = server;

export const mockExpiresInSeconds = 5400;
export const defaultAutoSaveMillisecondInterval = 120000;
export const autoSaveEventType = "autosave";
export const apiRedirectToLoginPagePath = "/";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const dateWindowStartMonth = 6; // 0 based, july is 6

export const GA_TRACKING_CODE = "G-TW1MHKHCQ1";

export const SiteSectionsForTeamMember = {
    DesktopMonitoring: createDescriptionObjectForList(1, "Desktop Monitoring"),
    PoliciesAndProcedures: createDescriptionObjectForList(2, "Policies And Procedures"),
    GMS: createDescriptionObjectForList(3, "Grant Management System")
};

export const SiteSectionsForTeamMemberList = [
    {context: contexts.MONITORING_COMPLIANCE, ...createListItem(SiteSectionsForTeamMember.DesktopMonitoring.id, SiteSectionsForTeamMember.DesktopMonitoring.description)},
    {context: contexts.SPP, ...createListItem(SiteSectionsForTeamMember.PoliciesAndProcedures.id, SiteSectionsForTeamMember.PoliciesAndProcedures.description)},
    {context: contexts.GMS, ...createListItem(SiteSectionsForTeamMember.GMS.id, SiteSectionsForTeamMember.GMS.description)}
];

export let environment = {};
export const SERVER_API_MODE = "SERVER_API_MODE";
export const MOCK_API_MODE = "MOCK_API_MODE";

if (process.env.NODE_ENV === 'production') {
    environment = {
        API_MODE: SERVER_API_MODE, // always should be server
        delay: 0
    }
} else {
    environment = {
        API_MODE: (process.env.NODE_ENV === 'test') ? MOCK_API_MODE : SERVER_API_MODE, // mock or server
        delay: 0
    }
}
